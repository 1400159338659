import React, {useState} from 'react';
import {Link} from 'gatsby';
import {IoIosSearch, IoIosClose} from 'react-icons/io';
import {DrawerProvider} from '../drawer/drawer-context';
import Menu from './menu';
import MobileMenu from './mobile-menu';
import SearchContainer from '../../containers/search/search';
import HeaderWrapper, {
    NavbarWrapper,
    Logo,
    MenuWrapper,
    NavSearchButton,
    NavSearchWrapper,
    SearchCloseButton,
    NavSearchFromWrapper,
} from './navbar.style';
import LogoImage from '../../images/logo.svg';
/*import {graphql} from 'gatsby';
import GatsbyImage from '../gatsby-image';*/

type NavbarProps = {
    className?: string;
};

const MenuItems = [
    {
        label: 'Home',
        url: '/',
    },
    {
        label: 'About',
        url: '/about',
    },
    {
        label: 'Contact',
        url: '/contact',
    },
    {
        label: 'Download App',
        url: 'https://vehicle24x7.com',
    },
];

const Navbar: React.FunctionComponent<NavbarProps> = ({
                                                          className,
                                                          ...props
                                                      }) => {
    const [state, setState] = useState({
        toggle: false,
        search: '',
    });

    const toggleHandle = () => {
        setState({
            ...state,
            toggle: !state.toggle,
        });
    };

    // Add al classs to an array
    const addAllClass = ['header'];

    // cla ssName p rop checking
    if (className) {
        addAllClass.push(className);
    }

    /*const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "logo.svg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 220
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `)*/

    return (
        <HeaderWrapper className={addAllClass.join(' ')} {...props}>
            <NavbarWrapper className="navbar">
                <DrawerProvider>
                    <MobileMenu items={MenuItems} logo={LogoImage}/>
                </DrawerProvider>
                <Logo>
                    <Link to="/">
                        <img src={LogoImage} alt="logo"/>
                        {/*<GatsbyImage src={data.file.childImageSharp.gatsbyImageData} alt="logo" />*/}
                    </Link>
                </Logo>
                <MenuWrapper>
                    <Menu items={MenuItems}/>
                </MenuWrapper>
                <NavSearchButton
                    type="button"
                    aria-label="search"
                    onClick={toggleHandle}
                >
                    <IoIosSearch size="23px"/>
                </NavSearchButton>
            </NavbarWrapper>

            <NavSearchWrapper className={state.toggle ? 'expand' : ''}>
                <NavSearchFromWrapper>
                    <SearchContainer/>
                    <SearchCloseButton
                        type="submit"
                        aria-label="close"
                        onClick={toggleHandle}
                    >
                        <IoIosClose/>
                    </SearchCloseButton>
                </NavSearchFromWrapper>
            </NavSearchWrapper>
        </HeaderWrapper>
    );
};

export default Navbar;
